<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { authMethods, notificationMethods } from "@/state/helpers";
import { REGISTER } from "@/state/actions.type";

import Loader from "@/components/loader";
import Common from "../../../helpers/Common";

export default {
  components: { Loader },
  data() {
    return {
      user: { company_name: "", email: "", password: "", country: "VN"},
      masterMerchantKey:"",
      // masterMerchantType:"",
      is_login_allowed: false,
      send_kyc_link_email: false,
      is_invite_merchant: false,
      use_master_merchant_creds: false,
      isMasterMerchant:'false',
      selectEntityTypeModel:true,
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      agreeTrmsAndCondition:false,
      countries: []

    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    user: {
      company_name: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function(value) {
          return /[#?!@$%^&*-]/.test(value)
        }
      },
      country: { required },
    },
  },
  created() {
    this.countries = Common.countryList();
    var queryParams = new URLSearchParams(window.location.search)
    this.user.company_name = queryParams.get("merchantName")
    this.user.email = queryParams.get("emailId")
    this.user.country = queryParams.get("countryCode") || "VN"
    this.masterMerchantKey = queryParams.get("masterMerchantKey")
    // this.masterMerchantType = queryParams.get("masterMerchantType")
    this.is_login_allowed = queryParams.get("isLoginAllowed");
    this.send_kyc_link_email = queryParams.get("sendKycLinkEmail");
    this.is_invite_merchant = queryParams.get("isInviteMerchant");
    this.use_master_merchant_creds = queryParams.get("useMasterMerchantCreds");
    if(this.masterMerchantKey){
      this.selectEntityTypeModel = false
      this.isMasterMerchant = 'false'
    }
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    ...notificationMethods,

    // Try to register the user in with the email, company_name
    // and password they provided.
    tryToRegisterIn() {
      this.$store.dispatch("notification/clear");
      if(!this.agreeTrmsAndCondition){
        return;
      }
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToRegister = true;
        // Reset the regError if it existed.
        this.regError = null;
        return (
          this.$store
            .dispatch(`auth/${REGISTER}`, {
              company_name: this.user.company_name,
              name: this.user.company_name,
              brand_name: this.user.company_name,
              email: this.user.email,
              password: this.user.password,
              country: this.user.country,
              is_master_merchant:this.isMasterMerchant,
              master_merchant_key:this.masterMerchantKey,
              // master_merchant_type:this.masterMerchantType,
              is_login_allowed: this.is_login_allowed,
              is_invite_merchant: this.is_invite_merchant,
              use_master_merchant_creds: this.use_master_merchant_creds,
              send_kyc_link_email: this.send_kyc_link_email,
              cross_border_enabled: this.$route.query.crossBorderEnabled
            })
            // eslint-disable-next-line no-unused-vars
            .then((responseData) => {
              this.tryingToRegister = false;
              this.isRegisterError = false;
              this.registerSuccess = true;
              if (this.registerSuccess) {
                var nextRoute = { name: "General Settings" };
                if(responseData.permissions.includes('view-dashboard')) {
                  nextRoute = { name: "Dashboard" };
                }
                this.$router.push(nextRoute);
              }
            })
            .catch((error) => {
              this.tryingToRegister = false;
              this.regError = error ? error : "";
              this.isRegisterError = true;
            })
        );
      }
    },

    terms_condition_checkbox(){
      this.agreeTrmsAndCondition = !this.agreeTrmsAndCondition
    },

    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    },

    resetNotificationError() {
      this.$store.dispatch("notification/clear");
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-black"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <Loader v-if="tryingToRegister === true"></Loader>
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-10">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/portone.png"
                              height="30"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-3">Register account</h4>
                        <p class="text-muted">Get your PortOne account now.</p>
                      </div>

                      <div class="p-2 mt-4">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                          >Registration successful. Please verify the email address from the verification email sent to you.</b-alert
                        >

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="resetNotificationError"
                          >{{ notification.message }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToRegisterIn"
                        >
                          <div v-if="selectEntityTypeModel" class="form-group font-size-12">
                            <label class="text-muted d-block">Select Your Entity Type</label>
                            <div
                              class="px-1 py-1" id="single-merchant-cont"
                              style="font-size: 12px; display: inline-block;"
                            >
                              <div class="checkbox-group">
                                <input type="radio" id="single_entity" value="false" v-model="isMasterMerchant">
                                <label for="single_entity" class="position-relative mb-0"
                                :class="{
                                    'filled': isMasterMerchant == 'false'
                                  }">Single Merchant</label>
                              </div>
                            </div>
                            <b-tooltip target="single-merchant-cont">
                              <p>
                                {{
                                  "You are a single business entity and will accept transactions for only this entity."
                                }}
                              </p>
                            </b-tooltip>
                            <div
                              class="ml-2 px-1 py-1" id="master-merchant-cont"
                              style="font-size: 12px; display: inline-block;"
                            >
                              <div class="checkbox-group">
                                <input type="radio" id="master_entity" value="true" v-model="isMasterMerchant">
                                <label for="master_entity" class="position-relative mb-0"
                                :class="{
                                    'filled': isMasterMerchant == 'true'
                                  }">Master Merchant</label>
                              </div>
                            </div>
                            <b-tooltip target="master-merchant-cont">
                              <p>
                                {{
                                  "If you want onto onboard mutliple business entities linked to a Master merchant choose ‘Master Merchant’ option."
                                }}
                              </p>
                            </b-tooltip>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="company_name">Company Name</label>
                            <input
                              v-model="user.company_name"
                              type="text"
                              class="form-control"
                              id="company_name"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.company_name.$error,
                              }"
                              placeholder="Enter Company Name"
                            />
                            <div
                              v-if="submitted && !$v.user.company_name.required"
                              class="invalid-feedback"
                            >
                              Company Name is required.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.user.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.user.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.user.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.user.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-1">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                            />
                          </div>
                          <div
                            v-if="submitted && !$v.user.password.required"
                            class="error"
                            style="font-size: 80%;"
                          >
                            Password is required.
                          </div>
                          <div class="mb-4">
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.user.password.minLength">Password must have at least {{$v.user.password.$params.minLength.min}} letters.</div>
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.user.password.containsUppercase">Password must have Uppercase letter.</div>
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.user.password.containsLowercase">Password must have Lowercase letter.</div>
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.user.password.containsNumber">Password must have Numeric letter.</div>
                            <div class="error" style="font-size: 80%;" v-if="submitted && !$v.user.password.containsSpecial">Password must have Special Character.</div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-parent-line auti-custom-input-icon"
                            ></i>
                            <label for="country">Country</label>

                            <b-form-select name="country" v-model="user.country" class="form-control">
                              <b-form-select-option v-for="country in countries" :key="country" :value="country">{{ getRegionName(country) }}</b-form-select-option>
                            </b-form-select>

                          </div>

                          <div
                              class="mb-4" id="terms-checkbox"
                              style="font-size: 12px;"
                            >
                              <div class="checkbox-group">
                                <input @click="terms_condition_checkbox" type="radio" id="terms_condition_checkbox">
                                <label for="terms_condition_checkbox" class="position-relative mb-0"
                                :class="{
                                    'filled': agreeTrmsAndCondition
                                  }">I agree to the PortOne Global</label> <a class="text-chai" href="https://terms.portone.io/global/en/may2024">Terms of Service</a><span style="color: red;font-size: 16px;"> *</span>
                              </div>
                            </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              :class="{
                                'disabled': !agreeTrmsAndCondition
                              }"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <p class="mb-0">
                              By registering you agree to the PortOne Terms and
                              Conditions
                              <a href="#" class="text-primary">Terms of Use</a>
                            </p>
                          </div> -->
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Already have an account ?
                          <router-link
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          >
                        </p>
                        <p>
                          © <a href="https://portone.io/global/en" class="text-primary">PortOne SG Pte. Ltd</a>
                          <!-- Crafted with <i class="mdi mdi-heart text-danger"></i> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background: #ebebeb;" class="col-lg-8">
            <div class="authentication-bg authentication-bg-signup">
              <!-- <div class="bg-overlay"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group{
  display: inline-block;
}
.checkbox-group  label {
  cursor: pointer;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #fc6b2d;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group  label.filled:before {
    background-color: rgba(252,107,45,.1);
    border: 1px solid #fc6b2d;
}

.checkbox-group label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 1px solid #fc6b2d;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.authentication-page-content{
  height: auto;
}
</style>